import { AsyncPipe } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, input, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ImageDirective } from '../../../../../@common/DOM/image.directive';
import { Event } from '../../../../../@domain/event/entities/event.interface';
import { AppRoutes } from '../../../../../routing/types/app-route';

@Component({
    selector: 'app-event-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ RouterLink, ImageDirective, AsyncPipe ],
    styles: `
        @import "../../../../../../styles/variables";
        @import "../../../../../../styles/functions";

        a {
            text-decoration: none;
        }

        .card-body .info {
            display: flex;
            justify-content: space-between;
            margin-top: 2.25rem;
        }

        .event-list-view {
            display: flex;
            align-items: center;
            padding: 0.5rem;
            margin: 0.5rem 0;
            border-radius: 4px;
            cursor: pointer;

            .event-title {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 0.25rem;
            }

            img {
                height: 60px;
                width: 150px;
                min-width: 150px;
                margin-right: 1.5rem;
                border-radius: 0.35rem;

                @media screen and (max-width: 600px) {
                    width: 100px;
                    min-width: 100px;
                    margin-right: 0.5rem;
                }

                @media screen and (max-width: 600px) {
                    width: 75px;
                    min-width: 75px;
                }
            }

            .right {
                display: flex;
                align-items: center;
                width: 100%;

                strong {
                    color: $dark;
                    width: 60%;
                    margin-right: 1.5rem;
                }

                div {
                    display: flex;
                    width: 40%;

                    span {
                        width: 50%;
                    }
                }

                @media screen and (max-width: 767px) {
                    flex-direction: column;

                    strong {
                        width: 100%;
                        margin-right: 0;
                    }

                    div {
                        width: 100%;

                        span {
                            width: fit-content;
                            margin-right: 1rem;
                        }
                    }
                }
            }
        }
    `,
    template: `
        @if (!listView()) {
            <a class="card" routerLink="{{AppRoutes.Events}}/{{ event.slug }}">
                <div class="card-header">
                    <img [src]="event.image || 'assets/download.png'" [alt]="event.title + ' image'"
                         [attr.loading]="priorityImageLoading() ? 'eager' : 'lazy'"
                         [attr.fetchpriority]="priorityImageLoading() ? 'high' : 'low'"
                         [attr.decoding]="'async'"
                    />
                </div>
                <div class="card-body">
                    <h3>{{ event.title }}</h3>
                    <div class="info">
                        <p>{{ event.date }}</p>
                        <p>&nbsp;·&nbsp;</p>
                        <p>{{ event.num_lectures ?? 0 }} videos</p>
                    </div>
                </div>
            </a>
        } @else {
            <a class="event-list-view" routerLink="/{{AppRoutes.Events}}/{{ event.slug }}">
                <img [src]="event.image || 'assets/download.png'" alt="video-img"
                     [attr.loading]="priorityImageLoading() ? 'eager' : 'lazy'"
                     [attr.fetchpriority]="priorityImageLoading() ? 'high' : 'low'"
                     [attr.decoding]="'async'"
                />
                <div class="right">
                    <strong class="event-title">{{ event.title }}</strong>
                    <div class="numbers">
                        <span class="light-color">{{ event.date }}</span>
                        <span class="light-color">{{ event.num_lectures ?? 0 }} Videos</span>
                    </div>
                </div>
            </a>
        }
    `
})
export class EventItemComponent {
    @Input() public event!: Event;
    public priorityImageLoading = input(false, { transform: booleanAttribute });
    public listView = input(false, { transform: booleanAttribute });
    public AppRoutes = AppRoutes;
}
